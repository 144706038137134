<template>
  <div class="newCampaign">
    <div class="container">
      <navigation />
      <div class="innerWrapper">
        <div class="row">
          <div class="col-lg-6">
            <!-- Target Section -->
            <campaign-target :campaign="campaign" @getEstimate="getEstimate" />
            <!-- Budget Section -->
            <campaign-budget
              ref="budget"
              :campaign="campaign"
              :newCampaign="newCampaign"
              @getEstimate="getEstimate"
              @submit="createCampaign"
              @continue="openModal"
            />
          </div>
          <div class="col-lg-6">
            <!-- Audience Definition Section -->
            <campaign-audience-defination :campaign="campaign" />
          </div>
        </div>
      </div>
    </div>
    <!-- Instructions -->
    <b-modal ref="my-modal" id="instructions" centered>
      <div class="title">{{$t('Instruction')}}</div>
      <p class="subTitle">
        {{$t('What do you want the local Influencers to include in their posts?')}}
      </p>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(submit)">
          <base-input
            name="Title"
            :placeholder="$t('Title')"
            type="text"
            v-model="newCampaign.name"
            rules="required"
          />
          <base-media-accounts
            rules="required"
            v-model="newCampaign.account_id"
            name="Media Account"
            :place-holder="$t('Media Account')"
          ></base-media-accounts>

          <!--Audience Type start-->
          <base-select 
            :options="audienceType" 
            v-model='selectAudienceType'
            @input="itemChanged"
            class="audience-type"
            name="Audience Type"
          />
          <base-input
            name="Url"
            :placeholder="$t('Url')"
            type="text"
            v-model="newCampaign.mediaUrl"
            v-if='audienceTypeVisitor'
            rules="required"
          />
          <!--Audience Type End-->

          <base-input
            name="Instagram"
            :placeholder="$t('@Our lnstagram')"
            type="text"
            v-model="newCampaign.mediaHandle"
            rules="required"
            v-if="audienceTypeAwareness"
          />
          <base-input
            name="Hast Tag"
            placeholder="#Our hashtag"
            type="text"
            v-model="newCampaign.mediaTags"
            rules="required"
            v-if="audienceTypeAwareness"
          />
          <base-file-upload
            name="Image"
            v-model="newCampaign.image"
            :label="$t('Add an image')"
            rules="required"
          ></base-file-upload>

          <base-text-area
            v-model="newCampaign.description"
            :placeholder="$t('Ex: I want you to talk about Glowl.me')"
            :label="$t('Instructions and campaign details for influencers:')"
            name="Description"
          ></base-text-area>
          <button
            type="submit"
            class="btn btn-primary"
            :class="processing ? 'processing' : ''"
          >
            {{$t('continue')}}
          </button>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Instructions -->

    <thank-you-modal
      :modal-show="showThankYou"
      :title="thankYouMessage"
    ></thank-you-modal>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { mapState, mapActions } from "vuex";
import { CAMPAIGN_ESTIMATE } from "../../graphql/brand/query";
import { CREATE_CAMPAIGN } from "../../graphql/brand/mutations";
import debounce from "lodash/debounce";
import { MESSAGES } from "@/_helpers/notifications";
export default {
  data() {
    return {
      campaign: {},
      estimatefilters: {},
      showThankYou: false,
      thankYouMessage: MESSAGES.CAMPAIGN_CREATED,
      processing: false,
      newCampaign: {
        image: null,
        mediaHandle: null,
        mediaTags: null,
        description: null,
        name: null,
        account_id: [],
        budget: 0,
        mediaUrl: null,
      },
      audienceType: [
        { value: "visitors", text: this.$i18n.t('Visitors') },
        { value: "awareness", text: this.$i18n.t('Awareness') },
      ],
      audienceTypeVisitor: false,
      audienceTypeAwareness: false,
      selectAudienceType: 'visitors',
    };
  },
  components: {
    CampaignTarget: () =>
      import(
        /* webpackChunkName: "campaignTarget" */ "@/components/brand/campaigns/new/CampaignTarget.vue"
      ),
    CampaignBudget: () =>
      import(
        /* webpackChunkName: "compaignBudget" */ "@/components/brand/campaigns/new/CompaignBudget.vue"
      ),
    CampaignAudienceDefination: () =>
      import(
        /* webpackChunkName: "campaignAudienceDefination" */ "@/components/brand/campaigns/new/CompaignAudienceDefination.vue"
      ),
    navigation: () =>
      import(
        /* webpackChunkName: "navigations" */ "@/components/brand/Navigation.vue"
      ),
    ThankYouModal: () =>
      import(
        /* webpackChunkName: "navigations" */ "@/components/common/ThankYouModal.vue"
      ),
  },
  computed: {
    ...mapState({
      filters: (state) => state.campaign.filters,
    }),
  },
  methods: {
    getEstimate: debounce(function () {
      this.estimatefilters = cloneDeep(this.campaign);
      delete this.estimatefilters["__typename"];
      this.estimatefilters.location = this.estimatefilters.location.map(
        (item) => Number(item.id)
      );
      this.estimatefilters.interests = this.estimatefilters.interests.map(
        (id) => Number(id)
      );
      this.estimatefilters.tag = this.estimatefilters.tag.map((item) =>
        Number(item.id)
      );
      //this.estimatefilters.gender = Number(this.estimatefilters.gender);
      this.$apollo
        .query({
          query: CAMPAIGN_ESTIMATE,
          fetchPolicy: "network-only",
          variables: {
            filter: { ...this.estimatefilters },
          },
        })
        .then((data) => {
          if (data) {
            this.$store.commit(
              "campaign/SET_ESTIMATES",
              data.data.campaignEstimate
            );
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    }),
    createCampaign() {
      this.newCampaign = { ...this.campaign, ...this.newCampaign };
      this.newCampaign.location = this.newCampaign.location.map((item) =>
        Number(item.id)
      );
      this.newCampaign.interests = this.newCampaign.interests.map((id) =>
        Number(id)
      );
      this.newCampaign.gender = this.newCampaign.gender.toString();
      this.newCampaign.tag = this.newCampaign.tag.map((item) =>
        Number(item.id)
      );
      this.$apollo
        .mutate({
          mutation: CREATE_CAMPAIGN,
          variables: {
            account_id: Number(this.newCampaign.account_id.value),
            name: this.newCampaign.name,
            description: this.newCampaign.description,
            influencers: this.newCampaign.influencers,
            followers: this.newCampaign.followers,
            location: this.newCampaign.location,
            category: this.newCampaign.interests,
            budget: this.newCampaign.budget,
            priceStart: this.newCampaign.priceStart,
            priceEnd: this.newCampaign.priceEnd,
            rating: this.newCampaign.rating,
            ageStart: this.newCampaign.ageStart,
            ageEnd: this.newCampaign.ageEnd,
            gender: this.newCampaign.gender,
            posts: this.newCampaign.posts,
            mediaHandle: this.newCampaign.mediaHandle,
            mediaTags: this.newCampaign.mediaTags,
            image: this.newCampaign.image,
            tag: this.newCampaign.tag,
            type: "post",
            mediaUrl: this.newCampaign.mediaUrl,
          },
        })
        .then((data) => {
          if (data) {
            if (data.data.createCampaign.state == "success") {
              this.showThankYou = true;
              this.$refs["my-modal"].hide();
              this.resetForm();
              this.processing = false;
            } else {
              this.notify("error", data.data.createCampaign.msg);
            }
          }
        })
        .catch((e) => {
          this.processing = false;
          this.handleError(e);
        });
    },
    openModal() {
      this.$refs["my-modal"].show();
    },
    submit(e) {
      this.processing = true;
      this.createCampaign();
    },
    resetForm() {
      this.newCampaign.image = null;
      this.newCampaign.mediaHandle = null;
      this.newCampaign.mediaTags = null;
      this.newCampaign.description = null;
      this.newCampaign.name = null;
      this.newCampaign.account_id = [];
      this.newCampaign.budget = 0;
    },
    itemChanged(event) {
      //console.log(this.newCampaign);
      if (event === "visitors") {
        this.audienceTypeVisitor = true;
        this.audienceTypeAwareness = false;
      } else if (event === "awareness") {
        this.audienceTypeVisitor = false;
        this.audienceTypeAwareness = true;
      } else {
        this.audienceTypeVisitor = false;
        this.audienceTypeAwareness = false;
      }
    },
  },
  watch: {
    filters: {
      handler() {
        if (this.filters.default) {
          this.campaign = cloneDeep(this.filters.default);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.newCampaign {
  ::v-deep {
    .v-select-toggle {
      color: var(--textPrimary) !important;
      font-weight: 600 !important;
      height: 40px !important;
      min-width: 170px;
      padding-left: rem(16px);
      padding-right: rem(50px);
      @media screen and (max-width: 767px) {
        font-size: rem(18px);
      }
      .arrow-down {
        width: 16px;
        height: 8px;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.828' height='8.414' viewBox='0 0 14.828 8.414'%3E%3Cg id='dropdown' transform='translate(0.414 0.414)'%3E%3Cpath id='Path_180' data-name='Path 180' d='M1,1,7,7l6-6' fill='none' stroke='%23c9ced6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
    .statistics__earning {
      display: block !important;
    }
  }
  @media screen and (max-width: 991px) {
    .row {
      > div {
        &:first-child {
          margin-bottom: rem(17px);
        }
      }
    }
  }
  .contentBox {
    .interests {
      .multiselect {
        &__content-wrapper {
          &__content {
            max-height: 250px !important;
            overflow: auto !important;
          }
        }
      }
    }
  }
}
::v-deep {
  .multiselect {
    border: solid 1px #ccd4e0;
    border-radius: 8px;
  }
  .multiselect__content-wrapper {
    border-color: #ccd4e0;
  }
  .audience-type .v-select-toggle {
    border: 1px solid #ccc;
  }
}
</style>
